.search-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D1D1D1;
    padding-left: 10px;
    padding-bottom: 5px;
}

input {
    margin-left: 10px;
    width: 500px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #616161;
}

input {
    border: none;
    outline: 1px transparent;
}

input::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #616161;
}

input::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #616161;
}
  
input::-moz-placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #616161;
}
  
input:-ms-input-placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #616161;
}

@media (max-width: 900px) {
    .search-container input{
        width: 100%;
    }
    .search-container{
        width: 100%;
    }
}