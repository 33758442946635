.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    transition: 0.5s;
}
.overlay.w-0 {
    width: 0;
}
.overlay.w-full {
    width: 100%;
}

.sales-details-modal {
    width: 460px;
    height: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    overflow: auto;
    padding: 30px;
    background: #FFFFFF;
    transition: right 0.3s ease-out;
}

.slide-out {
    right: -600px;
}

.slide-in {
    right: 0;
}

.sales-details-modal .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sales-details-modal h4 {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
}
.heading{
    background: transparent !important;
}

.close-sales-modal {
    cursor: pointer;
}

.sales-details-modal .user-info {
    margin-top: 30px;
}

.sales-details-modal .time {
    margin-top: 20px;
}

.sales-details-modal .info {
    display: flex;
    align-items: center;
    gap: 100px;
    margin-bottom: 20px;
}

.sales-details-modal .info p {
    width: 150px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.sales-details-modal .info span {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}



.sales-modal-item-qty-amount-container {
    margin-top: 20px;
}

.sales-modal-item-qty-amount {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
    border-bottom: 1px solid #D1D1D1;
}

.sales-modal-item-qty-amount .qty-amount {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 8px;
}

.sales-modal-item-qty-amount .qty-amount .qty, .sales-modal-item-qty-amount .qty-amount .amount {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.sales-modal-item-qty-amount .qty-amount .qty .first, .sales-modal-item-qty-amount .qty-amount .amount .first {
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}

.sales-modal-item-qty-amount .qty-amount .qty .second, .receipt-item-qty-amount .qty-amount .amount .second  {
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111111 !important;
}

.view-receipt {
    width: 100%;
    margin-top: 40px;
    background: #080B24;
    border-radius: 8px;
    padding: 12px 0px;
    text-align: center;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nunito';
    color: #FFFFFF;
}

.recover-sale{
    display: flex;
    justify-content: center;
    padding: 10px 0;
    /* border: 1px solid red; */
}
.recover-sale p{
    color: var(--stockkeepercolours-black, #111);
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.recover-sale p span{
    color: var(--stockkeepercolours-brand-colours-2, #F08227);
    cursor: pointer;
}

@media (max-width: 900px) {
    .sales-details-modal{
        width: 100%;
    }
}