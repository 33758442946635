.new-item {
  padding: 40px 0 0;
}
.new-item_input {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.0025em;
  color: #a3a3a3;
  font-family: "Nunito";
  /* border: 2px solid red; */
}
.new-item_input::placeholder {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.0025em;
  color: #a3a3a3;
  font-family: "Nunito";
}

.new-item-body {
  padding: 30px 0;
}
.new-item_body-form {
  display: flex;
  /* border: 2px solid red; */
  gap: 70px;
  padding: 20px 0;
}
.new-item-body-left {
  width: 65%;
}
.new-item-body-right {
  width: 35%;
}
.new-item-body-left h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #616161;
}
.new-item-left_form-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.new-item-body-right h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #616161;
}
.new-item-body-right_img {
  width: 344px;
  height: 326px;
  background: #f7f7f7;
  border: 1px dashed #a3a3a3;
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}
.new-item-body-right_img p {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #a3a3a3;
}

.custom-fields {
  padding: 20px 0;
  width: 40%;
}
.custom-fields h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.custom-fields p {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #080b24;
}
.custom-fields_form {
  padding: 20px 0;
}
.custom-fields_single-input {
  display: flex;
  gap: 40px;
  align-items: flex-end;
}
.custom-fields_single-input_icons {
  display: flex;
  gap: 25px;
  padding-bottom: 30px;
  color: #616161;
}

.item-variant_container {
  width: 405px;
}
.new-item_bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 90px 0 0;
}
.new-item_bottom-left,
.new-item_bottom-right {
  width: 40%;
}

.new-item-variant_show {
  /* border: 1px solid red; */
  display: flex;
  gap: 20px;
  padding: 20px 0;
}
.item-variant_option {
  position: relative;
  /* display: flex; */
  align-items: center;
  gap: 20px;
}
.new-item_bottom-right {
  /* border: 1px solid red; */
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: flex-end;
}
.new-item_bottom-right p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #080b24;
  cursor: pointer;
}
.add-folder_btn-dropdown {
  /* border: 1px solid red; */
  padding-top: 0;
}

@media (max-width: 900px) {
  .new-item_body-form {
    /* border: 1px solid red; */
    flex-direction: column;
  }
  .new-item-body-left {
    width: 100%;
  }
  .new-item-left_form,
  .new-item-body-right {
    width: 90%;
  }
  .new-item-left_form-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .new-item_bottom-left,
  .new-item_bottom-right {
    width: 100%;
  }
  .new-item_bottom {
    flex-direction: column;
  }
  .new-item_bottom-right {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    padding-top: 30px;
  }
  .new-item_input {
    /* border: 2px solid red; */
    width: 100%;
  }
}
