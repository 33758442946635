.support-options-container {
    width: 100%;
    margin-top: 20px;
    background: #FDFDFD;
    border: 0.0625rem solid #B2B3BB;
    border-radius: 0.5rem;
    padding: 1.25rem 0rem 1.25rem 1.25rem;
}

.support-options-container .flex-items {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
}

p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #393C50;
}