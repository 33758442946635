
.quantity-bar{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}
.quantity-bar_left{
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
}
.quantity-bar_left h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #616161;
    display: flex;
    align-items: center;
    gap: 10px;
}
.quantity-bar_right{
    /* border: 1px solid green; */
    /* display: flex; */
    gap: 16px;
    justify-content: flex-end;
}
.icon-wrap{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}
.active-wrap{
    background: #FEF3E9;
    color: #F08227
}
.inactive-wrap{
    color: #616161;
    border: 1px solid #D1D1D1;
}

.quantity-bar_display{
    /* border: 1px solid red; */
    padding: 50px 0;
    width: 100%;
}
.quantity-bar_display-folder{
    padding-bottom: 40px;
}
.quantity-grid_display{
    display: grid;
    grid-gap: 45px; 
    grid-template-columns: repeat(3, 1fr);
}
.quantity-bar_header {
    margin-bottom: 20px;
    border-bottom: 1px solid #D1D1D1;
}
.quantity-bar_header h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}
.quantity-grid_item{
    display: flex;
    flex-direction: column;
    padding: 24px 0px 0px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
}
.quantity-grid_item:hover{
    border: 1px solid #080B24;
}
.quantity-grid_item-focus{
    border: 1px solid #F08227 !important;
}
.quantity-grid_item-top{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 20px;
}
.quantity-grid_item-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    background: #FEF3E9;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.quantity-grid_item-bottom h5{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    text-transform: capitalize;
}
.quantity-grid_item-bottom h4{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
    text-transform: capitalize;
}
.folder-icon_wrap{
    width: 56px;
    height: 56px;
    background: #E9F0F7;
    border-radius: 50%;
    color: #F08227;
    display: flex;
    justify-content: center;
    align-items: center;
}
.quantity-grid_item-top h6{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #616161;
    text-transform: capitalize;
}
.quantity-grid_item-middle h3{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #111111;
    padding-bottom: 40px;
    width: 85%;
    margin: 0 auto;
    text-transform: capitalize;
}

.quantity-block_display{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.quantity-block_items{
    width: 100%;
    height: 202px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.quantity-block_item-focus{
    border: 1px solid #F08227!important;
}
.quantity-block_items:hover{
    border: 1px solid #080B24;
}
.quantity-block_items-left h3{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 30px 16px;
    color: #111111;
    text-transform: capitalize;
}
.quantity-block_items-right_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.quantity-block_items-right_top h6{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    text-transform: capitalize;
}
.quantity-block_items-right_bottom{
    padding-top: 40px;
}
.quantity-block_items-right_bottom h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    text-align: right;
    text-transform: capitalize;
}
.quantity-block_items-right_bottom h4{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
    padding: 10px 0;
    text-transform: capitalize;
}

.item-grid_display{
    display: grid;
    grid-gap: 45px; 
    grid-template-columns: repeat(3, 1fr);
}
.item-grid_item{
    padding: 8px 0px 0px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
}
.item-grid_item:hover{
    border: 1px solid #080B24;
}
.item-image_header{
    position: relative;
    padding: 0 10px;
    height: 139px;
    backdrop-filter: blur(5px);
}
.item-image_header img{
    width: 100%;
    height: 100%;
    border-radius: 16px;
}
.item-image_header-span{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding: 0 10px;
    position: absolute;
    top: 10px;
}
.item-image_header-span p{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 8px;
    height: 20px;
    background: #DA568F;
    border-radius: 4px;
}
.item-grid_middle{
    padding: 10px 20px;
}
.item-grid_middle-top{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.item-grid_middle-top h3{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
    padding: 10px 0;
    text-transform: capitalize;    
}
.items-more_list{
    position: absolute;
    width: 136px;
    /* height: 129px; */
    filter: drop-shadow(0px 18px 28px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
    background-color: white;
    padding: 10px;
    right: 0;
    top: 45px;
    z-index: 2;
}
.items-more_list p{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    gap: 10px;
    display: flex;
    align-items: center;
    color: #616161;
    padding: 10px 0;
    margin: 10px 0;
    cursor: pointer;
}
.item-grid_middle span{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Nunito';
    font-style: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin: 10px 0;
    gap: 8px;
    border-radius: 100px;
}
.item-grid_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    gap: 26px;
    background: #E9F0F7;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.item-grid_bottom h5{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #616161;
}
.item-grid_bottom h6{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
}

.item-block_display{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.item-block_item{
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}
.item-block_display-left{
    display: flex;
    gap: 40px;
    align-items: center;
}
.item-block_variant {
    position: relative;
}
.item-block_variant img{
    width: 144px;
    height: 144px;
    border-radius: 16px;
}
.item-block_variant p{
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 8px;
    height: 20px;
    background: #DA568F;
    border-radius: 4px;
    top: 10px;
    left: 10px;
}
.item-block_display-name h3{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: hsl(0, 0%, 7%);
    padding: 30px 0;
    text-transform: capitalize;
}
.item-block_display-name span{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
    border-radius: 100px;
}
.items-block_display-right-top{
    display: flex;
    gap: 30px;
    align-items: center;
    position: relative;
    justify-content: flex-end;
}
.item-display_block-right_bottom{
    padding: 30px 0 0;
}
.item-display_block-right_bottom h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    text-align: right;
}
.item-display_block-right_bottom h6{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
    padding-top: 10px;
}
.stock-status{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item-block_display-name{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 900px) {
    .quantity-bar_left{
        gap: 10px;
    }
    .quantity-grid_display{
        display: flex;
        flex-direction: column;
    }
    .item-grid_display{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
    }
    .icon-wrap{
        display: none;
    }
}