
.dashboard-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    height: 40px;
    background: #080B24;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF; 
    text-transform: capitalize;
    cursor: pointer;   
}
.dashboard-btntrans{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    height: 40px;
    border: 1px solid #D1D1D1;
    background: transparent;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #616161;  
    text-transform: capitalize;
    cursor: pointer;  
}
.dashboard-variant{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    height: 40px;
    border: 1px solid #080B24;
    background: transparent;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #080B24;  
    text-transform: capitalize;
    cursor: pointer;  
}