.item-img_upload {
  padding: 11px 88px;
  height: 72px;
  background: #f7f7f7;
  border: 1px dashed #a3a3a3;
  border-radius: 8px;
  font-weight: 400;
  line-height: 18px;
  color: #a3a3a3;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.item-img_upload p {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
  text-align: center !important;
}

.settings-picture_upload {
  gap: 25px;
  padding: 30px 0;
  align-items: center;
}
.pic-display {
  display: none;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
}

@media (max-width: 900px) {
  .upload-btn{
    display: inline-flex;
    border: none;
  }
}
