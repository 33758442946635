.table-heading-container-component {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.table-heading-container-component h4 {
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}

.input-container-component {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.input-container-component .today {
    padding: 8px 10px;
    border: 1px solid #A3A3A3;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.input-container-component .today .text {
    color: #A3A3A3;
}

.input-container-component .today .date {
    color: #EBEBEB;
}
