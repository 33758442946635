
.toggle-bar_cont{
    width: 64px;
    height: 32px;
    position: relative;
    background: #E6E7E9;
    border-radius: 32px;
    cursor: pointer;
}
.toggle-ball{
    position: absolute;
    width: 25.6px;
    height: 25.6px;
    top: 3px;
    right: 4px;
    background: #F08227;
    border-radius: 50%;
}
.off-toggle{
    position: absolute;
    width: 25.6px;
    height: 25.6px;
    top: 3px;
    left: 4px;
    background: #A3A3A3;
    border-radius: 50%;
}