
.add-items_popup{
    overflow: auto;
    width: 560px;
    height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0 !important;
    padding: 30px 30px 10px;
}
.add-items_popup-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.add-items_popup-head h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.add-item_form{
    padding: 25px 0;
}
.item-variant_cont{
    /* border: 1px solid red; */
    padding: 20px 0;
}
.item-variant_option{
    position: relative;
    /* display: flex; */
    align-items: center;
    gap: 20px;
}
.item-variant_option h4{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616161;
}
.item-variant_option img{
    width: 12px;
    height: 12px;
    cursor: pointer;
}
.item-variant_show{
    padding: 20px 0;
}
.item-show_allfields{
    padding: 40px 0;
}
.item-show_allfields p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: center;
    color: #111111;
}
.item-add_business{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-end;
}
.add-folder_btn-dropdown{
    /* border: 1px solid red; */
    padding-top: 0;
}

.delete-modal{
    height: auto !important;
    width: 480px;
    border-radius: 16px !important;
}
.delete-head h3{
    color: var(--STOCK-KEEPER-COLOURS-Black, #111);
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 125% */
}
.delete-head p{
    color: var(--STOCK-KEEPER-COLOURS-greys-greys-900, #616161);
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 16px 0;
}
.delete-btn{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

@media (max-width: 900px) {
    .add-items_popup{
        width: 100%;
        height: 80%;
    }   
    .item-add_business{
        gap: 10px;
    }
}