.nav-bar{
    background: #F7F7F7;
}
.mobile-nav{
    display: none;
}
.desktop-nav{
    display: flex;
}
.nav-bar_content{
    /* border: 1px solid red; */
    align-items: center;
    width: 85%;
    margin: 0 auto;
    padding: 20px 0;
    gap: 10px;
    justify-content: space-between;
}
.nav-ul{
    /* border: 1px solid green; */
    width: 50%;
}
.nav-ul ul{
    list-style: none;
    display: flex;
    /* gap: 70px; */
    justify-content: space-evenly;
}
.nav-ul ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #616161;
    text-transform: capitalize;
    /* padding-bottom: 5px; */
    cursor: pointer;
}
.nav-buttons{
    display: flex;
    gap: 20px;
}




/* MOBILE STARTS HERE  */
@media (max-width: 900px) {
    .desktop-nav{
        display: none;
    }
    .mobile-nav{
        display: flex;
    }
    .nav-bar_content{
        width: 90%;
        margin: 0 auto;
    }
    .mobile-menu{
        position: relative;
    }
    .mobile-ul{
        /* border: 1px solid red; */
        position: fixed;
        top: 0;
        right: -15px;
        width: 70vw;
        min-height: 100vh;
        background: white;
        text-align: center;
        z-index: 10;
    }
    .mobile-ul_menu{
        width: 40px;
        height: 40px;
        background: #F4F4F4;
        border-radius: 50%;
        color: #F08227;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
    }
    .mobile-ul ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 40px 0;
    }
    .mobile-ul ul li{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        text-transform: capitalize;
        width: 40%;
        margin: 0 auto;
        padding-bottom: 5px;
    }
    .nav-buttons{
        justify-content: center;
        flex-direction: column;
        margin-top: 30px;
        gap: 20px;
        padding: 0 20px;
    }
    .btn,.btn-trans{
        width: 100%;
    }
}