.standalone-form {
  display: flex;
  /* border: 1px solid green; */
  margin: 50px 0;
  gap: 30px;
}
.standalone-form_left {
  /* border: 1px solid red; */
  width: 65%;
}
.standalone-form_right {
  /* border: 1px solid red; */
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.standalone-form_right img {
  width: 100%;
  height: 326px;
  border-radius: 8px;
}
.standalone-form_left-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.standalone-form_right-icon_cont {
  display: flex;
  gap: 10px;
}
.standalone-form_right-edit_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 12px;
  width: 40px;
  height: 40px;
  background: #f08227;
  border-radius: 8px;
  color: white;
}
.standalone-form_right-delete_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 12px;
  width: 40px;
  height: 40px;
  background: #b50000;
  border-radius: 8px;
  color: white;
}
.selected {
  background: #f08227;
  color: #ffffff !important;
  border-radius: 8px;
}
.custom-fields {
  padding: 20px 0;
  width: 40%;
}
.custom-fields h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.custom-fields p {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #080b24;
}
.custom-fields_form {
  padding: 20px 0;
}
.custom-fields_single-input {
  display: flex;
  gap: 40px;
  align-items: flex-end;
}
.custom-fields_single-input_icons {
  display: flex;
  gap: 25px;
  padding-bottom: 30px;
  color: #616161;
}
.standalone-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.standalone-header {
  position: relative;
}
.move-to_folder {
  /* border: 1px solid red; */
  position: absolute;
  bottom: -240px;
  right: 0;
  width: 480px;
  /* height: 256px; */
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 4;
  padding: 24px;
}
.move-to_folder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #111111;
  padding: 20px 0;
}
.move-to_folder-header h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #111111;
}
.move-to_folder p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  padding: 10px 10px;
  text-transform: capitalize;
  cursor: pointer;
}
.move-to_folder p:hover {
  background: #f08227;
  color: #ffffff !important;
  border-radius: 8px;
}
.move-to_folder-btn {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
}

@media (max-width: 900px) {
  .standalone-form {
    flex-direction: column;
  }
  .standalone-form_left {
    width: 90%;
  }
  .standalone-form_left-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .standalone-form_right{
    width: 100%;
  }
  .standalone-buttons{
    flex-direction: column;
    gap: 10px;
    width: 100%;
    /* border: 1px solid red; */
  }
  .special-bt{
    width: 100% !important;
  }
  .move-to_folder{
    width: 100%;
    left: 0;
  }
}
