.pagination-container {
    font-family: 'DM Sans';
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #393C50;
    background: #F7F7F7;
    padding: 20px 10px;
}

.pagination-container .page-numbers {
    position: relative;
    padding: 4px;
    background: #FFFFFF;
}

.total-pages {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
}

.pagination-container .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.pagination-container .page-numbers .dropdown {
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    top: 110%;
    left: 0;
    filter: drop-shadow(0px 18px 28px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
}

.page-numbers .number {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}
/* .dropdown {
    border: 1px solid red;
    height: 100px;
    overflow-y: auto;
} */

.page-numbers .dropdown p {
    padding: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #111111;
}
.page-numbers .dropdown p:hover {
    cursor: pointer;
    background: #F7F7F7;
}