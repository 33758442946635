
.single-custom_fields{
    width: 680px;
    height: 666px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 40px;
}
.single-field_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.single-field_head h6{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}

.single-fields_form{
    padding: 60px 0;
    width: 70%;
    margin: 0 auto;
}
.single-fields_btn{
    display: flex;
    justify-content: space-between;
}