.select-container {
    width: 100%;
    height: fit-content;
    margin-top: 1.25rem;
    position: relative;
}

.select-container label {
    display: block;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #616161;
    margin-bottom: 10px;
}

.select-container input {
    width: 100%;
    height: 2.5rem;
    margin: 0rem !important;
    outline: 1px transparent !important;
    display: block;
    line-height: 1.5rem;
    padding-left: 0.625rem;
    color: #080B24;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 0.5rem;
}

.select-container select {
    border: none !important;
}

.select-container input:focus {
    outline: 1px transparent !important;
}

.select-container input::placeholder {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}

.pr {
    padding-right: 40px;
}

