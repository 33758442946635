.layout-container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.content {
    overflow: auto;
    height: 100vh;
    width: 100%;
    padding: 30px;
}

@media (max-width: 900px) {
 .content{
    padding: 16px;
 }   
}