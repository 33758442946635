

.alert-threshold{
    position: relative;
}
.alert-threshold img{
    position: absolute;
    right: 10px;
    top: 58px;
    cursor: pointer;
}
.alert-threshold_icon{
    position: absolute;
    right: 10px;
    top: 58px;
    cursor: pointer;
    color: #A3A3A3;
}