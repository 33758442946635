.activity-history-component {
    margin-top: 50px;
}

.activity-history {
    display: flex;
    gap: 40px;
}

.activity-history .date-time .activity-history-date {
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111111;
}

.activity-history .date-time .activity-history-time {
    margin-top: 10px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616161;
}

.circle-with-line-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle-with-line-container .circle-first {
    background: #F08227;
    border-radius: 50%;
    border: 1px solid #F08227;
    width: 8px;
    height: 8px;
}

.circle-with-line-container .line-first {
    border: 1px solid #F08227;
    height: 86px;
    width: 0px;
}

.circle-with-line-container .circle {
    border-radius: 50%;
    border: 1px solid #F08227;
    width: 8px;
    height: 8px;
}

.circle-with-line-container .line {
    border: 1px solid #FEF3E9;
    height: 86px;
    width: 0px;
}

.transaction-performer-container .transaction {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
}

.transaction-performer-container .performer {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    color: #111111;
}


.empty-activity-history-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.empty-activity-history-container p {
    font-family: 'Nunito';
    font-style: 'normal';
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;;
    text-align: center;
    color: #616161;
}