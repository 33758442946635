.expense-table-heading-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.expense-table-heading-container h4 {
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}

.expense-input-container {
    display: flex;
    gap: 20px;
}

.expense-input-container .today {
    padding: 8px 10px;
    border: 1px solid #A3A3A3;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.expense-input-container .today .text {
    color: #A3A3A3;
}

.expense-input-container .today .date {
    color: #EBEBEB;
}

/* expense modal */

.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    transition: 0.5s;
}

.overlay.w-0 {
    width: 0;
}

.overlay.w-full {
    width: 100%;
}

.expense-modal {
    width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 20px;
}

.expense-modal.opacity-0 {
    opacity: 0;
    z-index: -10;
}

.expense-modal.opacity-1 {
    opacity: 1;
}

.expense-modal-heading {
    display: flex;
    justify-content: space-between;
}

.expense-modal-heading h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}

.expense-modal-heading .close {
    cursor: pointer;
}

.expense-modal .pt {
    margin-top: 1.25rem;
}

.expense-modal .btn-hold {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.add-expense {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    width: 180px;
    height: 40px;
    background: #080B24;
    border-radius: 8px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

@media (max-width: 900px) {
    .expense-modal{
        width: 90%;
    }
}