.billing-container,
.payment-method-container,
.payment-history-container {
  border: 1px solid #b2b3bb;
  border-radius: 8px;
  padding: 20px;
}

.billing-container {
  margin-top: 30px;
}

.payment-method-container,
.payment-history-container {
  margin-top: 20px;
}

.payment-history-container {
  margin-bottom: 10px;
}

.billing-plan {
  display: flex;
  justify-content: space-between;
}

.plan-type {
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan-type .duration {
  height: fit-content;
  background: #f08227;
  border-radius: 4px;
  padding: 2px 8px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.arrow-left {
  transform: rotate(45deg);
}

.billing-duration-amount {
  display: flex;
  align-items: center;
  gap: 7px;
}

.billing-duration-amount .amount {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
}

.billing-duration-amount .duration {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #616161;
}

.billing-plan .btn-hold {
  margin-top: 10px;
}

.payment-container {
  width: 420px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.payment-method-container span {
  color: #616161;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 15px;
}

.visa-container {
  display: flex;
  gap: 10px;
}

.visa-container div {
  height: fit-content;
}

.visa-container .first {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #111111;
}

.visa-container .second,
.visa-container .third p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #a3a3a3;
}

.visa-container .third {
  margin-top: 5px;
  display: flex;
  gap: 4px;
  align-items: center;
  height: fit-content;
}

.payment-container .edit {
  color: #f08227;
  border: 1px solid #f08227;
  border-radius: 4px;
  padding: 4px 8px;
  outline: 1px transparent;
  height: fit-content;
}

.payment-history {
  margin-top: 30px;
}

.heading {
  display: flex;
  background: #e9f0f7;
}

.heading p {
  padding: 12px 24px;
  color: #616161;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: 25%;
}

.payment-history-detail {
  display: flex;
}

.payment-history-detail p {
  padding: 12px 24px;
  color: #111111;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 25%;
}

.capitalize {
  text-transform: capitalize;
}

.status-btn {
  outline: 1px transparent;
  text-transform: capitalize;
  height: fit-content;
  margin: auto 0;
  background: #367c39;
  border-radius: 4px;
  padding: 0px 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.price-card {
  overflow: auto;
  width: 560px;
  height: auto;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 10;
  transition: 0.7s;
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
  /* border-radius: 20px; */
  padding: 30px 30px 10px;
}

.price-card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-plan {
  display: flex;
  padding: 30px 24px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--stockkeepercolours-primary-primary-100, #b2b3bb);
  background: var(--stockkeepercolours-greys-greys-100, #f7f7f7);
  margin: 30px 0;
}
.plan-left{
    display: flex;
    gap: 10px;
    align-items: center;
}
.plan-right{
    display: flex;
    gap: 10px;
    align-items: center;
}
.plan-left h3{
    color: var(--stockkeepercolours-black, #111);
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}
.plan-left p span{
    color: #FFF;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; 
    border-radius: 4px;
    background: var(--stockkeepercolours-brand-colours-2, #F08227);
    display: flex;
    padding: 2px 8px;
}
.plan-right h3{
    color: var(--stockkeepercolours-black, #111);
    text-align: center;
    font-family: Nunito;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: -0.09px;
}
.plan-right p{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
}

.toggle-btn{
    padding: 0 !important;
}

.toggle-btn h4{
    font-size: 20px !important;
}
.toggle-bg{
    width: 48px !important;
    height: 24px !important;
    padding: 0px 1.708px 0.854px 0px !important;
    align-items: center !important;
}
.toggle-click{
    width: 20px !important;
    height: 20px !important;
    top: 2px;
}
.plans-card_cont{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}

.plans-card_content{
    display: flex;
    padding: 30px 24px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--stockkeepercolours-primary-primary-100, #B2B3BB);
    background: var(--stockkeepercolours-greys-greys-50, #FDFDFD);
}
.plans-card_content-left{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.plans-card_content-left h3{
    color: var(--stockkeepercolours-black, #111);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
}
.plans-card_content-left p{
    color: var(--stockkeepercolours-black, #111);
    font-family: Nunito;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: -0.09px;
    display: flex;
    gap: 5px;
    align-items: center;
}
.plans-card_content-left p span{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.switch-plan_cont h2{
    color: var(--stockkeepercolours-black, #111);
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 125% */
}
.switch-plan_cont p{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
}
.switch-plan_btn{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.switch-plan_cont{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0;
}

@media (max-width: 900px) {
  .payment-history-container{
    overflow-x: auto;
    width: 100%;
  }
  .payment-history{
    width: 150%;
  }
  .price-card{
    width: 100%;
    height: 85%;
  }
  .current-plan, .plans-card_content{
    flex-direction: column;
    gap: 15px;
  }
  .plans-card_content{
    align-items: flex-start;
  }
  .price-card{
    width: 100%;
  }
  .switch-plan_btn{
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding-top: 20px;
  }
}