.customer-table{
    padding: 20px 0;
}
.customer-table_head{
    display: flex;
    padding: 0.75rem 1.5rem;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    flex: 1 0 0;
    background: var(--stockkeepercolours-accent-2-accent-250, #E9F0F7);
    /* margin-top: 20px; */
}
.customer-table_body p, .customer-table_head p{
    width: 25%;
    /* border: 1px solid red; */
}
.customer-table_body p{
    text-transform: capitalize;
}
.customer-table_body{
    display: flex;
    padding: 1.0rem 1.5rem;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
}

@media (max-width: 900px) {
    .customer-table{
        width: 100%;
        overflow-x: auto;
    }
    .customer-table-cont{
        width: 150%;
    }
}