.dashboard-header{
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard-headerleft{
    /* border: 1px solid red; */
    flex: 1;
}
.dashboard-headerleft h1{
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.0025em;
    color: #252C32;
    text-transform: capitalize;
}
.dashboard-headerright{
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    gap: 10px;
}
.header-variation_cont{
    flex-direction: column;
    position: relative;
}
.header-variation{
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}
.dashboard-input::placeholder{
    color: #111111 !important;
    text-transform: capitalize;
}
.header-variation-menu{
    /* border: 1px solid red; */
    height: auto !important;
    position: absolute;
    top: 46px;
    display: block;
    width: 262px;
    height: 172px;
    background: #FFFFFF;
    box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 4;
}
#header-variation{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #111111;
    padding: 10px 16px;
}

.notification-select{
    position: relative;
}

.notification-select_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    gap: 10px;
    height: 40px;
    border: 1px solid #A3A3A3;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
    cursor: pointer;
}
.notification-select_block p{
    text-transform: capitalize;
}
.notification-select_block p span{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #EBEBEB;    
}
.notification-select_menu{
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    top: 45px;
    width: 262px;
    height: 569px;
    background: #FFFFFF;
    box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
    z-index: 4;
}
.notsItem{
    width: 100%;
}
.notification-select_menu p{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #111111;
    text-transform: capitalize;
    padding: 12px 16px;
}
.notsActive{
    background: #F7F7F7;
}

.date-picker_component p{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3A3A3;
}
.date-picker{
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    width: 90%;
    margin: 0 auto;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    overflow: hidden !important;
    cursor: pointer;
}
.nots-btn{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 16px;
    padding-top: 30px ;
    width: 100%;
}

.standalone-style{
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    border: 1px solid #A3A3A3;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}
.more-list p{
    /* border: 1px solid red; */
    margin: 10px 0;
}


@media (max-width: 900px) {
    .dashboard-header{
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}