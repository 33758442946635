* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  max-width: 2000px;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
li,button {
  font-family: "Nunito", sans-serif;
}
p {
  font-family: "Raleway", sans-serif;
}
input,button,select, textarea{
  outline: none;
  font-family: 'Raleway';
}
label{
  font-family: 'Raleway';
}
