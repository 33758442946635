.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    transition: 0.5s;
}

.overlay.w-0 {
    width: 0;
}

.overlay.w-full {
    width: 100%;
}

.add-team-member-modal {
    overflow-y: auto;
    width: 560px;
    min-height: auto;
    max-height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 20px 40px;
    background: #FFFFFF;
    /* border-radius: 20px; */
}

.add-team-member-modal.opacity-0, .add-role-modal.opacity-0 {
    opacity: 0;
    z-index: -10;
}

.add-team-member-modal.opacity-1, .add-role-modal.opacity-1 {
    opacity: 1;
}

.add-team-member-modal-heading, .add-role-modal-heading {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.add-team-member-modal h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}

.add-team-member-modal .close,.add-role-modal .close {
    cursor: pointer;
}

.add-team-member-modal .content, .add-role-modal .content {
    /* width: 400px; */
    margin: 0 auto;
}
.add-team_content{
    /* width: 80%; */
    margin: 20px auto;
}

.permissions-container {
    margin-top: 20px;
}

.permissions-container h4 {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
}

.permissions-container span {
    display: block;
    margin-top: 20px;
    color: #A3A3A3;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.option {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.permissions-container .option p {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #111111;
}

.add-team-member-modal .btn-hold, .add-role-modal .btn-hold {
    /* margin-top: 30px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-team_btn-cont{
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}

.back-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #080B24;
    border: 1px solid #080B24;
    border-radius: 8px;
    width: 164px;
    height: 48px;
}

.invite-btn, .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #080B24;
    border-radius: 8px;
    width: 164px;
    height: 48px;
}

.add-role-modal {
    width: 560px;
    /* min-height: auto; */
    height: 540px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 20px 40px;
    background: #FFFFFF;
    border-radius: 20px;
    /* overflow-y: auto; */
}
.btn-container{
    /* border: 1px solid red; */
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 900px) {
    .add-role-modal{
        width: 90%;
        padding: 20px 10px;
    }
    .btn-container, .add-team_btn-cont{
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
    .add-team-member-modal{
        width: 90%;
        height: 85%;
        padding: 20px 10px;
    }
    .btn-hold{
        flex-direction: column;
    }
}