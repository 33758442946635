.tool-tip{
    position: absolute;
    background: #F08227;
    bottom: -40px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.tool-tip p{
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 10px 5px !important;
    text-align: center !important;
    color: #FFFFFF !important;
}
.tool-tip-arrow{
    position: absolute;
    color: #F08227;
    top: -10px;
}