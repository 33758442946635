.display-none {
  display: none;
}
button{
  border: transparent;
  outline: none;
  background: none;
}
a{
  text-decoration: none;
  color: #080b24;
}
.display-flex {
  display: flex;
}
.display-block {
  display: block;
}
.low-opacity {
  opacity: 0.4;
  cursor: not-allowed !important;
}
.high-opacity {
  opacity: 1;
  cursor: pointer;
}
.go-back {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 44px;
  height: 44px;
  background: #ffffff;
  border: 2px solid #393c50;
  border-radius: 8px;
  cursor: pointer;
}
.active-label {
  color: #2266b0 !important ;
}
.active-input {
  color: #2266b0 !important ;
  border: 1px solid #2266b0 !important;
}
.focus-label {
  color: #111111 !important ;
}
.focus-input {
  color: #111111 !important ;
  border: 1px solid #111111 !important;
}
.error-label {
  color: #FF0000 !important ;
}
.error-input {
  color: #111111 !important ;
  border: 1px solid #FF0000 !important;
}
.overlay {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.5);
  transition: 0.5s;
}
.overlay.w-0 {
  width: 0;
}

.overlay.w-full {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

/* general component styles starts here  */
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  background: #080b24;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 8px;
  cursor: pointer;
}
.btn-red {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  background: red;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 8px;
  cursor: pointer;
}
.btn-green {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  background: green;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 8px;
  cursor: pointer;
}
.btn-trans {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  color: #080b24;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  background: transparent;
  text-transform: capitalize;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.btn-variant {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #080b24;
  padding: 12px 16px;
  gap: 12px;
  border: 1px solid #080b24;
  border-radius: 8px;
  background: transparent;
  text-transform: capitalize;
  cursor: pointer;
}
.btn-variant2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #080b24;
  padding: 12px 16px;
  gap: 12px;
  border: 1px solid #080b24;
  border-radius: 8px;
  background: transparent;
  text-transform: capitalize;
  cursor: pointer;
}

@media (max-width: 900px) {
  .btn, .btn-trans, .btn-variant, .btn-variant2{
    width: 100%;
  }
  .ref-tab_cont{
    width: 100%;
    overflow-x: auto;
  }
  .ref-tab_content{
    padding: 20px 0btn;
    width: 150%;
  }
}

/* layout starts here  */

/* auth-layout starts here  */
.auth-layout {
  background: #080b24;
  min-height: 100vh;
  position: relative;
}
.auth-layout_gradient {
  position: absolute;
  top: 0;
  right: 0;
}
.authpage-cont {
  position: relative;
  padding: 50px 0;
}
.authpage {
  padding: 60px 80px;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 50%;
  z-index: 1000;
  margin: 0 auto;
}

/* landing page starts here  */
.landing-page_hero-section {
  background: #f7f7f7;
  padding: 60px 0;
}
.landing-page_hero-section h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.0025em;
  color: #111111;
  text-align: center;
}
.landing-page_hero-section h1 span {
  color: #f08227;
}
.landing-page_hero-section p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #616161;
  width: 60%;
  margin: 0 auto;
  padding: 30px 0;
}
.landing-page_hero-section_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.landing-page_hero-pic {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 85%;
  margin: 0 auto;
}
.landing-page_hero-pic img {
  width: 100%;
}

.landing-page_services-section {
  padding: 60px 0;
}
.landing-page_services-section h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.0025em;
  color: #111111;
  text-align: center;
}
.landing-page_services-section h2 span {
  color: #f08227;
}
.landing-page_services-section p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #555555;
  width: 50%;
  margin: 0 auto;
  padding: 30px 0;
}
.landing-page_services-section_cards {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  padding: 40px 0;
}
.landing-page_services-section_cards-left,
.landing-page_services-section_cards-right {
  width: 50%;
}
.landing-page_services-section_cards-left {
  display: flex;
  flex-direction: column;
}
.landing-page_services-section_cards-left button {
  width: 200px;
}
.landing-page_services-section_cards-right img {
  width: 100%;
}
.landing-page_services-section_cards-left h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #111111;
  text-align: left;
}
.landing-page_services-section_cards-left h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #616161;
  padding: 25px 0;
  width: 70%;
}
.left-card_flex {
  width: 50%;
  /* border: 1px solid red; */
}
.left-card_flex h4 {
  width: 100%;
}
.landing-page_bottom-services {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.landing-page_bottom-services h6 {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.0025em;
  color: #111111;
}
.landing-page_bottom-services button {
  margin: 30px 0;
}
.download-mobile_app {
  display: flex;
  justify-content: space-between;
  background-image: url(/src/assets/landingpage/noise.png);
  background-color: #080b24;
  border-radius: 20px;
  width: 85%;
  margin: 30px auto;
  padding: 80px;
  background-size: cover;
  position: relative;
}
.download-mobile_app-left {
  width: 40%;
}
.download-mobile_app-left h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.0025em;
  color: #ffffff;
}
.download-mobile_app-left p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  padding: 25px 0;
}
.download-mobile_app-left_button {
  display: flex;
  gap: 25px;
}
.download-mobile_app-left_button h3{
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  padding: 25px 0;
}
.download-mobile_app-left_button img {
  width: 100%;
}
.download-mobile_app-right {
  position: absolute;
  right: 0;
  top: 0;
}
.download-mobile_app-right img {
  width: 100%;
  border-bottom-right-radius: 20px;
}

/* about us starts here  */
.about-us {
  background: #f7f7f7;
  padding: 50px 0;
}
.about-us h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.0025em;
  color: #111111;
  text-align: center;
}
.about-us h2 span {
  color: #f08227;
}
.about-us p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  color: #616161;
  width: 70%;
  margin: 0 auto;
  padding: 40px 0;
}
.about-us_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* services-page starts here  */
.services-page {
  background: #f7f7f7;
  padding: 50px 0;
}
.service-page_top h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.0025em;
  color: #111111;
  text-align: center;
}
.service-page_top h2 span {
  color: #f08227;
}
.service-page_top p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #616161;
  padding: 30px 0;
  width: 60%;
  margin: 0 auto;
}
.service-page_links {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.service-page_links p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a3a3a3;
  cursor: pointer;
}
.service-page_links-card_cont {
  display: flex;
  gap: 20px;
  row-gap: 60px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.service-page_links-card {
  width: 30%;
}
.service-card_logo {
  color: #f08227;
  width: 48px;
  height: 48px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 8px;
}
.service-page_links-card h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  padding: 20px 0;
}
.service-page_links-card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #616161;
  width: 70%;
  margin: 0 auto;
}

/* loginpage starts here  */
.loginpage-form {
  /* border: 1px solid red; */
  width: 70%;
  margin: 0 auto;
}
.login-form_input {
  /* border: 1px solid red; */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.login-form_input label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #616161;
}
.login-form_input input {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #a3a3a3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  height: 52px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  width: 100%;
  margin: 0 !important;
}
.password-input {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 10px;
}
.password-input input {
  /* border: 1px solid red !important; */
  border: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #A3A3A3;
}
.login-form_button {
  padding: 20px 0;
}
.login-form_button button {
  width: 100%;
}
.login-form_button button img {
  width: 24px;
  height: 24px;
}
.login-continue p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #616161;
  padding: 20px 0;
}
.login-page_external-links {
  display: flex;
  justify-content: center;
  gap: 70px;
}
.remember-me {
  display: flex;
  gap: 10px;
  padding: 20px 0;
  align-items: center;
}
.remember-me h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  padding-top: 15px;
}
.login-signup{
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.login-signup p{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
}
.login-signup p span{
  color: #F08227;
}


/*signup page starts here  */
.signup-cards_cont {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0;
  cursor: pointer;
}
.signup-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 20px;
  height: 56px;
  width: 65%;
  margin: 0 auto;
  border: 1px solid #e0e0e9;
  border-radius: 8px;
}
.signup-card p {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}
.my-container-class {
  border: 3px solid red !important;
}

/* onboarding starts here  */
.onboarding-card_cont {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0;
}
.onboarding-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  height: 108px;
  border: 2px solid #ebebeb;
  border-radius: 8px;
  cursor: pointer;
}
.onboarding-card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #111111;
}
.onboarding-card h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #616161;
}
.onboarding-button {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0;
}
.onboarding-button button {
  width: 100%;
}
.selected-text {
  color: #f08227 !important;
}
.selected-border {
  border: 1px solid #f08227;
}
.selected-opacity {
  opacity: 0.4;
}

/* selectInput starts here  */
.form-select {
  width: 100%;
  padding: 8px 16px;
  height: 52px;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111111; 
}

/* starts here  */
