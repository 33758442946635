.msg-container {
    width: 100%;
    background: #FDFDFD;
    border: 0.0625rem solid #B2B3BB;
    border-radius: 0.5rem;
    padding: 1.25rem 0rem 1.25rem 1.25rem;
}

.msg-sub-container {
    width: 65%;
}
.msg-btn-container {
    margin-top: 0.9375rem;
    display: flex;
    justify-content: flex-end;
}

.msg-btn {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #FFFFFF;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background: #080B24;
    border-color: #080B24;
    cursor: pointer;
}

@media (max-width: 900px) {
    .msg-sub-container{
        width: 90%;
    }
}