.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    transition: 0.5s;
}
.overlay.w-0 {
    width: 0;
}
.overlay.w-full {
    width: 100%;
}

.receipt-container {
    width: 500px;
    height: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    overflow: auto;
    padding: 30px;
    background: #FFFFFF;
    transition: right 0.3s ease-out;
}
.company-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.slide-out {
    right: -600px;
}

.slide-in {
    right: 0;
}

.receipt-container .heading {
    display: flex;
    justify-content: space-between;
}

.receipt-container h4 {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.close-receipt {
    cursor: pointer;
}

.receipt-info-container {
    margin-top: 30px;
    border: 1px solid #F08227;
    border-radius: 20px;
    padding: 30px 20px;
}

.receipt-info-container h5 {
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #F08227;
}

.receipt-info-container .items-container {
    margin-top: 20px;
}

.receipt-info-container .items-container .item {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    margin-bottom: 20px;
}

.receipt-info-container .items-container .item .title {
    width: 160px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #616161;
}

.receipt-info-container .items-container .item .info {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #616161;
}

.receipt-item-qty-amount-container {
    margin-top: 20px;
}
.qty-amount{
    width: 50%;
}

.receipt-item-qty-amount {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
    border-bottom: 1px solid #D1D1D1;
    /* gap: 40px; */
}

.receipt-item-qty-amount .qty-amount {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 8px;
}

 .qty-amount .receipt-item-qty-amount .qty-amount .amount {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.receipt-item-qty-amount .qty-amount .qty .first, .receipt-item-qty-amount .qty-amount .amount .first {
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #616161;
}

.receipt-item-qty-amount .qty-amount .qty .second, .receipt-item-qty-amount .qty-amount .amount .second  {
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #111111 !important;
}

.receipt-info-container .total-amount-container {
    margin-top: 30px;
    text-align: center;
}

#receipt-total-amount {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #616161;
}

.receipt-info-container .total-amount-container span {
    margin-top: 5px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    display: block;
    color: #080B24;
}


.print-receipt {
    width: 100%;
    margin-top: 40px;
    background: #080B24;
    border-radius: 8px;
    padding: 12px 0px;
    text-align: center;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Nunito';
    color: #FFFFFF;
}

@media (max-width: 900px) {
    .receipt-container{
        width: 100%;
    }
}