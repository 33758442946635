.reports-modal {
  /* border-radius: 20px; */
  background: #fff;
  width: 50.5rem;
  min-height: auto;
  max-height: 95%;
  /* border: 1px solid red; */
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 10;
  transition: 0.7s;
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 10px;
}

.modal-head {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.modal-head h3 {
  color: var(--dark-gray-dark-gray-2, #252c32);
  font-size: 1.5rem;
  font-family: Nunito;
  font-weight: 700;
  line-height: 1.875rem;
}
.report-table {
  padding: 20px 0;
}
.report-table_head {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex: 1 0 0;
  background: var(--stockkeepercolours-accent-2-accent-250, #e9f0f7);
  /* margin-top: 20px; */
}
.report-table_body p,
.report-table_head p {
  width: 25%;
  /* border: 1px solid red; */
}
.report-table_body {
  display: flex;
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
}
.low-stock span {
  background: #fff9e6;
  color: #ffc107;
  border-radius: 100px;
  padding: 6px 12px;
}
.out-of-stock span {
  background: #ffe6e6;
  color: #ff0000;
  border-radius: 100px;
  padding: 6px 12px;
}

.close-modal,
.filter-cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}
.total-balance {
  display: flex;
  gap: 10px;
  padding: 20px 0;
  justify-content: space-between;
}
.total-balance p {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media (max-width: 900px) {
  .reports-modal {
    width: 90%;
    height: 85%;
    padding: 10px;
  }
  .modal-head {
    flex-direction: column-reverse;
  }
  .close-modal {
    align-self: flex-end;
  }
  .report-table {
    padding: 20px 0;
  }
  .tab-cont {
    width: 100%;
    overflow-x: auto;
  }
  .tab-cont_content {
    width: 200%;
  }
  .total-balance{
    flex-wrap: wrap;
    gap: 20px;
  }
  .reports-analysis_card-left{
    width: 80%;
  }
}
