
.amount-cont{
    /* border: 1px solid red; */
    padding-top: 8px;
    /* margin: 0 0 20px; */
}
.amount-label label{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616161;
}
.amount-input{
    display: flex;
    align-items: center;
    margin: 12px 0 !important;
}
.amount-input_flag{
    display: flex;
    align-items: center;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    padding: 8px 16px;
    height: 52px;
    gap: 10px;
    width: 118px;
}
.amount-input_flag p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}
.amount-input input{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
    padding: 8px 16px;
    gap: 10px;
    width: 90%;
    height: 52px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
}

.date-picker_cont{
    width: 100%;
    padding: 10px 0 20px ;
}
.date-picker{
    margin: 0;
}
.date-picker_cont h4{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3A3A3;
    padding: 10px 0;
}