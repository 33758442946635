
.reports-analysis_card{
    border-bottom: 1px solid #D1D1D1;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.reports-analysis_card-left{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reports-analysis_card-left h3{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.reports-analysis_card-left P{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
}
.reports-analysis_card-right{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    width: 56px;
    height: 56px;
    border: 1px solid #D1D1D1;
    border-radius: 50%;
}
