.sidenav-container {
    width: 20%;
    background-color: #080B24;
    height: 100vh;
    overflow-y: auto;
    /* border: 2px solid red; */
}
  
.logo-img{
    /* border: 1px solid red; */
    padding: 0 1.25rem;
    margin: 40px 0 0;
    width: 100%;
}

.sidenav {
    margin-top: 1.875rem;
}

.nav-link {
    display: flex;
    padding: 0.625rem 1.25rem;
    gap: 1.5rem;
    text-decoration: none;
    color: #D1D1D1;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 10px;
}

.nav-text {
    height: 100%;
    margin: auto 0;
}

.selected-nav{
    border-left: 4px solid white;
    padding-left: 30px;
    margin-left: 10px;
}

.active {
    font-weight: 600;
    font-size: 1rem;
    background-color: #F08227;
    color: #FFFFFF;
    transition-property: color, background-color, border-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}
.mobile-menu{
    display: none;
}


@media (max-width: 900px) {
    .desktop-menu{
        display: none;
    }
    .mobile-menu{
        display: flex;
        /* border: 2px solid red; */
        position: relative;
        /* width: 100%; */
    }
    .menu-bar{
        position: absolute;
        top: 20px;
        left: 16px;
        z-index: 15;
    }
    .mobile-menu .sidenav-container{
        position: absolute;
        top: 0;
        left: 0;
    }
    .sidenav-container{
        width: 80vw;
        position: absolute;
        z-index: 15;
    }
    .sidenav{
        width: 100%;
    }
    .logo-img{
        padding-top: 20px;
    }
}