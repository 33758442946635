
.custom-fields{
    padding: 20px 0;
    width: 40%;
}
.custom-fields h3{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}
.custom-fields p{
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #080B24;
}
.custom-fields_form{
    padding: 20px 0;
}
.custom-fields_single-input{
    /* border: 1px solid red; */
    display: flex;
    gap: 40px;
    align-items: flex-end;
}
.custom-fields_single-input_icons{
    display: flex;
    gap: 25px;
    padding-bottom: 30px;
    color: #616161;
}
.date-icons{
    padding-bottom: 20px !important;
}

.suggested-fields{
    width: 680px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 25px;
}
.suggested-field_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.suggested-field_head h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.suggested-field_cont{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    padding: 50px 0;
}
.suggested-content{
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 20px;
    height: 72px;
    background: #FFFFFF;
    border: 2px solid #D1D1D1;
    border-radius: 16px;
    color: #616161;
    cursor: pointer;
}
.content-active{
    border: 2px solid #F08227 !important;
    color: #F08227 !important;
}
.contentp{
    color: #F08227 !important;
}
.suggested-content p{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #616161;
}
.suggested-field_btn{
    display: flex;
    justify-content: flex-end;
}