.profile {
    display: flex;
    position: relative;
}

.profile-text-arrow-hold {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.profile-img-hold img, .profile-modal-img-hold img{
    border-radius: 50%;
}

.profile-text {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
}

.arrow_down {
    margin-left: 10px;
}

.profile-img-hold {
    margin-left: 20px;
}

/* modal */

.profile-modal {
    z-index: 10;
    position: absolute;
    top: 100%;
    right: 0;
    width: 480px;
    padding: 20px 0px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.profile-modal-info-close {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.profile-modal-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.name-email-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.name-email-container #name {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
}

.name-email-container #email {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616161;
}

.profile-modal #settings {
    cursor: pointer;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 10px;
    margin: 0 auto;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2266B0;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
}

.profile-modal .plan {
    padding: 20px;
}

.profile-modal .plan #plan-name {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
}

.profile-modal .plan #plan-type {
    margin-top: 10px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3A3A3;
}

#logout {
    width: fit-content;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Raleway';
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #b50000;
}

@media (max-width: 900px) {
    .profile-modal{
        width: 100%;
    }
    .profile{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}