.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    transition: 0.5s;
}
.overlay.w-0 {
    width: 0;
}
.overlay.w-full {
    width: 100%;
}

.connect-to-printer {
    width: 480px;
    border-radius: 16px;
    background: white;
    padding: 20px;
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: right 0.3s ease-out;
}

.slide-out {
    top: -2000px;
}

.connect-to-printer-heading-container {
    display: flex;
    justify-content: space-between;
}

.connect-to-printer-heading-container h3 {
    font-family: 'Nunito';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #2C2828;
}

.connect-to-printer p {
    margin-top: 30px;
    font-family: 'Raleway';
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    color: #616161;
}

.connect-to-printer-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    width: 100%;
}



.connect, .print-receipt {
    margin: 0 auto;
    width: 100%;
    padding: 12px 0px;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.connect {
     color: #080B24;
     border: thin solid #080B24;
     background-color: white;
}

.print-receipt {
    transform: translateY(-20px);
    background: #080B24;
    color: white;
}

@media (max-width: 900px) {
    .connect-to-printer{
        width: 90%;
    }
}