@media (max-width: 900px) {
  /* auth-layout starts here  */
  .auth-layout_gradient img {
    width: 271px;
    height: 220px;
  }
  .authpage {
    width: 90%;
    padding: 30px 10px;
  }

  /* landing page  */
  .landing-page_hero-section h1 {
    font-size: 36px;
    line-height: 44px;
  }
  .landing-page_hero-section p {
    font-size: 14px;
    line-height: 22px;
    width: 87%;
  }
  .landing-page_hero-pic {
    padding: 20px 0;
  }
  .landing-page_hero-section_buttons {
    flex-direction: column;
  }
  .landing-page_hero-section_buttons button {
    font-size: 16px;
    line-height: 24px;
  }
  .landing-page_services-section {
    width: 90%;
    margin: 0 auto;
  }
  .landing-page_services-section h2 {
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    width: 80%;
  }
  .landing-page_services-section p {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    width: 100%;
    padding: 10px 0;
  }
  .landing-page_services-section_cards {
    flex-direction: column !important;
    width: 100%;
    gap: 20px;
    padding: 20px 0;
  }
  .landing-page_services-section_cards-left,
  .landing-page_services-section_cards-right {
    width: 100%;
  }
  .landing-page_services-section_cards-left h3 {
    font-size: 24px;
    line-height: 30px;
  }
  .landing-page_services-section_cards-left h4 {
    font-size: 14px;
    line-height: 22px;
    width: 100%;
  }
  .landing-page_services-section_cards-left button {
    font-size: 16px;
    line-height: 24px;
  }
  .landing-page_bottom-services h6 {
    font-size: 24px;
    line-height: 30px;
  }
  .landing-page_bottom-services button {
    font-size: 16px;
    line-height: 24px;
  }
  .download-mobile_app {
    flex-direction: column;
    width: 90%;
    padding: 40px 20px 0;
  }
  .download-mobile_app-left {
    width: 100%;
  }
  .download-mobile_app-left h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .download-mobile_app-left p {
    font-size: 14px;
    line-height: 22px;
  }
  .download-mobile_app-right {
    position: relative;
    /* border: 1px solid red; */
    margin-top: 30px;
  }
  .download-mobile_app-left_button {
    flex-direction: column;
    width: 50%;
  }

  /* about us  */
  .about-us h2 {
    font-size: 36px;
    line-height: 44px;
  }
  .about-us p {
    font-size: 14px;
    line-height: 22px;
    width: 90%;
    padding: 20px 0;
  }
  .about-us_button {
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
  }

  /* service page  */
  .service-page_top {
    width: 90%;
    margin: 0 auto;
  }
  .service-page_top h2 {
    font-size: 36px;
    line-height: 44px;
    text-align: left;
  }
  .service-page_top p {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    width: 100%;
  }
  .service-page_links {
    gap: 15px;
  }
  .service-page_links p {
    font-size: 14px;
    line-height: 22px;
  }
  .service-page_links-card_cont {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    row-gap: 40px;
    padding: 20px 0;
  }
  .service-page_links-card {
    width: 100%;
  }
  .service-card_logo {
    margin: 0;
  }
  .service-page_links-card h4 {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
  }
  .service-page_links-card p {
    text-align: left;
    width: 90%;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }

  /* login page  */
  .loginpage-form {
    width: 96%;
  }
  .login-form_input input {
    font-size: 14px;
    line-height: 22px;
  }
  .login-form_button button {
    font-size: 16px;
    line-height: 24px;
  }
  .login-page_external-links{
    gap: 30px;
  }

  .signup-card{
    width: 100%;
  }
  .signup-card p{
    font-size: 16px;
    line-height: 22px;
  }

  /* onboarding  */
  .onboarding-card_cont{
    width: 100%;
    padding: 15px 0;
  }
  .onboarding-card h4{
    font-size: 16px;
    line-height: 20px;
  }
  .onboarding-card h5{
    font-size: 12px;
    line-height: 18px;
  }
  .onboarding-button button{
    font-size: 16px;
    line-height: 24px;
  }
}
