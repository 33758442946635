.latest {
    position: relative;
    padding: 0px 16px;
    border: 1px solid #A3A3A3;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #616161;
    cursor: pointer;
}
.latest .dropdown {
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    top: 110%;
    left: 0;
    filter: drop-shadow(0px 18px 28px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
}
.latest .dropdown p {
    padding: 12px 20px;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #111111;
}
.latest .dropdown p:hover {
    cursor: pointer;
    background: #F7F7F7;
}