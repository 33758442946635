.recovery-container {
    margin-top: 30px;
}

.returned-recovery {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
    padding: 20px;
}
.returned h5 {
    color: #616161;
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.returned .numbers {
    margin-top: 20px;
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
}
.returned .comparison {
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #111111;
    gap: 5px;
}

.green {
    color: #4CAF50 !important;
}