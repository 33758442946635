footer{
    background: #F7F7F7;
}
.footer-content{
    width: 85%;
    margin: 0 auto;
    padding: 40px 0;
}
.footer-top h2{
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.0025em;
    color: #111111;
    padding: 40px 0 10px;
}
.footer-top_links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.footer-top_links h3{
    font-size: 24px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.0025em;
    color: #111111;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
}
.footer-bottom_right,.footer-bottom_left{
    width: 50%;
}
.footer-bottom_right-icons{
    display: flex;
    gap: 20px;
    padding: 20px 0;
}
.footer-bottom_right p{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #616161;
    padding: 20px 0;
    width: 70%;
}
.footer-bottom_left{
    display: flex;
    justify-content: space-evenly;
}
.footer-bottom_left-links p{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    text-transform: capitalize;
    padding: 10px 0;
}
.footer-bottom_left-links ul{
    list-style: none;
    padding: 20px 0;
}
.footer-bottom_left-links ul li{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    padding: 10px 0;
    color: #000000; 
    text-transform: capitalize;   
}



/* MOBILE STARTS HERE  */
@media (max-width: 900px) {
    .footer-content{
        width: 90%;
    }
    .footer-top h2{
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        padding: 20px 0;
    }
    .footer-top h2 span{
        color: #F08227;
    }
    .footer-top_links{
        justify-content: start;
    }
    .footer-top_links img{
        width: 40%;
    }
    .footer-bottom{
        flex-direction: column;
        padding-top: 70px;
    }
    .footer-bottom_right,.footer-bottom_left{
        width: 100%;
    }
    .footer-bottom_right p{
        font-size: 14px;
        line-height: 22px;
        width: 100%;
    }
    .footer-bottom_left{
        flex-direction: column;
        padding-top: 40px;
    }
    .footer-bottom_left-links p{
        font-size: 20px;
        line-height: 24px;
    }
    .footer-bottom_left-links ul li{
        font-size: 14px;
        line-height: 22px;
    }
}