

  .authhead-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .authhead-top img{
    margin: 20px 0;
  }
  .authhead-top h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
    width: 60%;
    text-align: center;
  }
  .authhead-top h3{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #616161; 
    padding: 15px 0; 
    width: 60%;
  }

  .logo-home{
    position: relative;
    /* border: 1px solid red; */
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo-home p{
    position: absolute;
    top: -10px;
    text-align: center;
    color: #f08227;
    align-items: center;
    gap: 5px;
  }

  /* check mark starts here  */
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #616161;
  border-radius: 8px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid #f08227;
  border-radius: 8px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #f08227;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.multi-select{
  width: 100%;
}
.multi-select h3{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  padding-bottom: 10px;
}

.multiple-select{
  /* margin: 20px 0; */
  position: relative;
}
.options-head{
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 8px;
  height: 40px;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
}
.options-head h3{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #111111;
}
.options-head h3 span{
  display: flex;
  color: #616161;
  gap: 20px;
}
.multiple-select_options{
  /* border: 1px solid red; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 20;
}
.multiple-select_options p, .multiple-select_options input{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  padding: 8px 16px;
  width: 100%;
}
.multiple-select_options p{
  margin: 4px 0;
}
.multiple-select_options h4{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  padding: 10px 16px;
  width: 100%;
  cursor: pointer;
}
.selected-css{
  border-radius: 8px;
  background: #f08227;
  color: #FFFFFF !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multiple-select_options p span{
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Search select  */
.dropdown-plate{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 13.4375rem; */
  padding: 1rem;
  align-items: flex-start;
  margin: 0 auto;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #E6E6E6;
  background: var(--stockkeepercolours-white, #FFF);
}
.dropdown-plate p{
  /* border: 1px solid red; */
  text-transform: capitalize;
  padding: 5px;
  width: 100%;
}
.selected-item{
  background: #f08227;
  color: #FFF;
  border-radius: 6px;
}


  @media (max-width: 900px) {
    .authhead-top h2, .authhead-top h3{
      width: 90%;
      margin: 0 auto;
    }
    
  }

  