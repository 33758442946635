
.advanced-search_body{
    padding: 30px 0;
}
.header-text{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    padding: 5px 0;
    border-bottom: 1px solid #D1D1D1;
    margin: 16px 0;
}
.items-more_list{
    top: 25px !important;
}

.members-display{
    display: grid;
    grid-gap: 20px; 
    grid-template-columns: repeat(4,1fr);
}
.members-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px 0;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
}
.members-item h3{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-transform: capitalize;
    text-align: center;
}
.members-item p{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
}
.item-grid_middle-top h3{
    cursor: pointer;
}
.quantity-grid_item-middle h3{
    cursor: pointer;
}