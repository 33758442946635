/* Hide the default checkbox */
input[type="checkbox"] {
  display: none;
}

/* Style the label as the custom checkbox */
.checkbox-label {
  display: inline-block;
  position: relative;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  border: 1px solid #A3A3A3;
  border-radius: 6px;
  background: #FFFFFF;
}

/* Style the label when the checkbox is checked */
input[type="checkbox"]:checked + .checkbox-label {
  background-color: #F08227;
  border: 1px solid #F08227;
}

/* Create a pseudo-element for the checkmark */
.checkbox-label:after {
  content: "\2713"; /* Unicode character for checkmark */
  font-size: 12px; /* Adjust as needed */
  color: #fff; /* Adjust the color as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

/* Display the checkmark when the checkbox is checked */
input[type="checkbox"]:checked + .checkbox-label:after {
  opacity: 1;
}