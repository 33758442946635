/* check mark starts here  */
/* Customize the label (the container-container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    /* margin-bottom: 12px; */
    width: 32px;
    height: 32px;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checktick {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 20px;
    width: 20px;
    border: 1px solid #A3A3A3;
    border-radius: 8px;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checktick {
    background-color: #f08227;
    border: 1px solid #f08227;
    border-radius: 8px;
  }
  
  /* Create the checktick/indicator (hidden when not checked) */
  .checktick:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checktick when checked */
  .checkbox-container input:checked ~ .checktick:after {
    display: block;
  }
  
  /* Style the checktick/indicator */
  .checkbox-container .checktick:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }