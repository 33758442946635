.stock-at-hand-container {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.stock-item {
  flex: 1;
  flex-basis: 0;
}



/* free trial style  */
.expired-sub{
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.info-cont{
  position: relative;
  /* border: 1px solid red; */
  height: 30px;
}
.info-content {
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  padding-top: 10px;
  position: absolute;
  bottom: -40%;
  left: 40%;
}
.info-content h4 {
  color: #111;
  text-align: center;
  /* Title/1 */
  font-family: Nunito;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 120% */
}
.info-content p {
  color: #111;
  text-align: center;
  font-family: Nunito;
  font-size: 0.77656rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #FAD8BC;
}

.line-chart-container {
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
}
.overview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.sales-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.bar-header {
  gap: 20px;
}
.summary-text-with-sales-and-revenues {
  /* border: 1px solid green; */
  display: flex;
  gap: 80px;
  align-items: center;
}
.bar-header_sub-heading {
  gap: 30px;
}
.summary-text-with-sales-and-revenues h3 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.summary-text-with-sales-and-revenues span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11.9183px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: #757575;
}

.summary-text-with-sales-and-revenues p {
  margin-top: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: #282828;
}

.total-sales-revenue {
  display: flex;
  gap: 30px;
}

.line-chart {
  width: 100%;
}

.recent-activities,
.top-sellers {
  margin-top: 30px;
  /* border: 1px solid red; */
  width: 100% !important;
  padding: 30px;
  border: 0.744891px solid #d1d1d1;
  border-radius: 8px;
  /* overflow-y: auto;
  height: 400px; */
}

.recent-activities h4,
.top-sellers h4 {
  margin-bottom: 30px;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.recent-activities-history {
  display: flex;
  gap: 40px;
}

.recent-activities-history .date-time .recent-activities-history-date {
  font-family: "Nunito";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}

.recent-activities-history .date-time .recent-activities-history-time {
  margin-top: 10px;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #616161;
}

.recent-activities-history .circle-with-line-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recent-activities-history .circle-with-line-container .circle-first {
  background: #f08227;
  border-radius: 50%;
  border: 1px solid #f08227;
  width: 8px;
  height: 8px;
}

.recent-activities-history .circle-with-line-container .line-first {
  border: 1px solid #f08227;
  height: 86px;
  width: 0px;
}

.recent-activities-history .circle-with-line-container .circle {
  border-radius: 50%;
  border: 1px solid #f08227;
  width: 8px;
  height: 8px;
}

.recent-activities-history .circle-with-line-container .line {
  border: 1px solid #fef3e9;
  height: 86px;
  width: 0px;
}

.recent-activities-history .transaction-performer-container .transaction {
  font-family: "Nunito";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #111111;
}

.recent-activities-history .transaction-performer-container .performer {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  color: #111111;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  /* border: 1px solid red; */
  margin-bottom: 30px;
}
.top h4 {
  /* border: 1px solid green; */
  margin: auto 0;
}

.items-heading {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
}

.items-heading p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #5a5c6c;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
  align-items: center;
}

.bar-chart-container {
  width: 100% !important;
  padding: 30px;
  border: 0.744891px solid #d1d1d1;
  border-radius: 8px;
  /* border: 1px solid red; */
  /* margin-top: 40px; */
}

.items .img-text-hold {
  display: flex;
  align-items: center;
  gap: 16px;
}
.img-text-hold img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.items .name {
  font-family: "Nunito";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.items .qty {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.items .amount {
  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.pie-chart-container {
  width: 100% !important;
  padding: 30px;
  border: 0.744891px solid #d1d1d1;
  border-radius: 8px;
  /* margin-top: 40px; */
}

.pie-chart-legend-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.pie-chart-legends {
  margin-top: 100px;
}

.total-expenses span {
  font-family: "Nunito";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
}

.total-expenses p {
  margin-top: 5px;
  font-family: "Nunito";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #282828;
}

.line-chart-legend {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.legend {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}

.legend-content {
  display: flex;
  width: 70px;
  align-items: center;
  gap: 4px;
}

.legend-content .label {
  font-family: "Nunito";
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #616161;
}

.round {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.legend .price {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #111111;
}


@media (max-width: 900px) {
  .overview-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .top{
    flex-wrap: wrap;
    gap: 15px;
  }
  .pie-chart-legend-container{
    flex-direction: column;
  }
  .info-content{
    bottom: -50px;
  }
  .top-sellers, .recent-activities, .line-chart-container, .bar-chart-container, .pie-chart-container{
    padding: 10px;
  }
  .tab-cont{
    /* border: 2px solid green; */
    overflow-x: auto;
  }
  .tab-content-scroll{
    /* border: 2px solid red; */
    width: 150%;
  }
  
}