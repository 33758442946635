.tabs-container {
    background: #F4F4F4;
    width: fit-content;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    transition-property: color, background-color, border-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    flex-wrap: wrap;
}

.tabs-container .active {
    color: #FFFFFF;
    background: #F08227;
}

.tabs-container span {
    cursor: pointer;
    text-transform: capitalize;
    display: inline-block;
    font-family: 'Raleway';
    font-style: normal;
    padding: 10px 40px;
    text-align: center;
    color: #616161;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

@media (max-width: 900px) {
    .tabs-container {
        gap: 10px;
    }
}