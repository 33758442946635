.table-container {
    width: 100%;
    margin-top: 30px;
    overflow: auto;
    display: flex;
}


.table-container::-webkit-scrollbar {
    width: 1em;
}

.table-container::-webkit-scrollbar-track {
    background-color: #F7F7F7;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #F08227;
}

.table-container::-webkit-scrollbar-button {
    background-color: #E6E6E6; /* Arrow background color */
    width: 10px; /* Arrow width */
    height: 16px; /* Arrow height */
}


table {
    min-width: 1400px;
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
}

thead tr {
    background: #E9F0F7;
}

tbody tr:hover {
    background: #F7F7F7;
    cursor: pointer;
}

th {
    padding: 12px 24px;
    text-align: start;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
}

td {
    line-height: 24px;
    padding: 12px 24px;
    text-align: start;
}

td p {
    color: #111111;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
}

.time {
    margin-top: 10px;
    color: #616161;
}