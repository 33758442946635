.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 900px) {
    .top-bar{
        flex-direction: column-reverse;
        /* border: 1px solid red; */
        gap: 20px;
        align-items: flex-end;
    }
}