.dashboard-back{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 8px;
    width: 56px;
    height: 56px;
    border: 1px solid #D1D1D1;
    border-radius: 50%;
    margin: 10px 0;
    cursor: pointer;
    color: #292D32;
}