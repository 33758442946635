
.active-cell{
    color: white;
    background: green;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
}
.inactive-cell{
    color: white;
    background: red;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
}
.capital{
    text-transform: capitalize;
}
.admin-msg_body{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.admin-text_area{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
}
.admin-text_area label{
    color: #616161;
}
.admin-text_area textarea{
    width: 100%;
    height: 250px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #111111;
    padding: 8px 16px;
    width: 100%;
}