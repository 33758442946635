.input-container {
    width: 100%;
    height: fit-content;
    margin-top: 1.25rem;
    position: relative;
}

.input-container label {
    display: block;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #616161;
    margin-bottom: 10px;
}

.input-container input {
    width: 100%;
    height: 3.125rem;
    margin: 0rem !important;
    display: block;
    line-height: 1.5rem;
    padding-left: 0.625rem;
    color: #080B24;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 0.5rem;
}

.pr {
    padding-right: 40px;
}

.eye-container {
    position: absolute;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    top: 35px;
    right: 15px;
    cursor: pointer;
}

