
.textarea-container {
    width: 100%;
    margin-top: 1.25rem;
}

.textarea-container label {
    display: block;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #616161;
    margin-bottom: 10px;
}

.textarea-container textarea  {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 0.5rem;
    font-weight: 400;
    color: #080B24;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 0.625rem;
    padding-left: 0.625rem;
    display: block;
    height: 10rem;
    width: 100% !important;
    outline: 0.0625rem transparent;
    resize: none;
}

.textarea-container textarea::placeholder {
    color: #A3A3A3;;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}