.notifications-component, .inventory-component {
    margin-top: 30px;
    padding: 24px 16px;
    display: flex;
    gap: 20px;
    background: #FDFDFD;
    box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.notifications-component h3, .inventory-component h3  {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    /* text-transform: capitalize; */
}

.notifications-component p, .inventory-component p {
    margin-top: 10px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
    /* text-transform: capitalize; */
}
.notifications-component p span, .inventory-component p span {
    padding-left: 5px;
    /* text-transform: capitalize; */
}

.empty-notification-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-notification-container p {
    font-family: 'Nunito';
    font-style: 'normal';
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;;
    text-align: center;
    color: #616161;
}

.yellow {
    color: #FFC107;
}

.red {
    color: #FF0000;
}

.pl {
    padding-left: 5px;
}