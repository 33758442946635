.folder-dashboard_header{
    position: relative;
}

.add-folder{
    width: 480px;
    /* height: 550px; */
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 40px;
}
.add-folder_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
.add-folder_head h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.add-folder_body{
    position: relative;
}
.add-folder-select{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 900px) {
    .add-folder{
        width: 90%;
        padding: 20px;
    }
}