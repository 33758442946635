

.add-new_btn{
    position: relative;
}
.add-folder_button{
    display: flex;
    gap: 2px;
    align-items: center;
    padding-bottom: 10px;
}
.add-folder_btn-dropdown{
    background: white;
    position: absolute;
    top: -110px;
    right: 0;
    padding: 10px;
    width: 200px;
    height: 100px;
    margin-left: auto;
}
.add-folder_btn-dropdown p{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #616161;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
}