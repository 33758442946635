.general-container {
    margin-top: 30px;
}

.email-container, .linked-container {
    border: 1px solid #B2B3BB;
    border-radius: 8px;
    padding: 20px;
}

.linked-container {
    margin-top: 20px;
    margin-bottom: 10px;
}

.email-container .small-heading, .linked-container .small-heading {
    color: #393C50;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nunito';
}

.email-container .text, .linked-container .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616161;
    font-family: 'Raleway';
}

.general-container .flex-items {
    display: flex;
    padding-top: 10px;
    align-items: center;
    justify-content: space-between;
}

.linked-container .flex-items {
    margin-top: 10px;
}

.google-frame-hold {
    display: flex;
    align-items: center;
    gap: 40px;
}