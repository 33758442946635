
.seclect-delete{
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}
.select-delete_left h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;    
}
.select-delete_right{
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    gap: 16px;
}
.edit-icon-wrap{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E9F0F7;
    border-radius: 8px;
    color: #2266B0;
}
.delete-icon-wrap{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF0000;
    border-radius: 8px;
    color: white;
}