.sales-component-container {
    margin-top: 30px;
}
.info-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.qty-amt-profit {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
    padding: 20px;
}
.qty h5 {
    color: #616161;
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.qty .numbers {
    margin-top: 20px;
    color: #111111;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
}
.qty .comparison {
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #111111;
    gap: 5px;
}
.top-selling {
    background: #111111;
    border-radius: 16px;
    padding: 16px 28px;
    width: 350px;
}
.top-selling h5 {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}
.top-selling .flex {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
.active-list {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}
ol li {
    cursor: pointer;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #616161;
}
.top-selling .flex h6 {
    color: #D1D1D1;
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}
.top-selling .flex span {
    color: #FFFFFF;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.top-selling .total {
    margin-top: 10px;
}
.top-selling .flex ol {
    margin-top: 10px;
}
.sales-history-header {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}
.sales-history-header h4 {
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
}
.sales-history-header .flex {
    display: flex;
    gap: 20px;
}
.sales-history-table-container {
    margin-top: 30px;
}

/* add a sale modal */
.overlay {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    transition: 0.5s;
}
.overlay.w-0 {
    width: 0;
}
.overlay.w-full {
    width: 100%;
}
.add-sale-modal {
    padding: 20px;
    width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 30px;
    background: #FFFFFF;
    /* border-radius: 20px; */
    overflow-y: auto;
    overflow-x: hidden;
    height: 95%;
}
.add-sale-modal.opacity-0 {
    opacity: 0;
    z-index: -10;
}
.add-sale-modal.opacity-1 {
    opacity: 1;
}
.add-sale-modal-heading {
    display: flex;
    justify-content: space-between;
}
.close {
    cursor: pointer;
}
.add-new-item-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.add-new-item-container p {
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #080B24;
    display: flex;
    align-items: center;
    gap: 10px;
}
.phone-p{
    cursor: pointer;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #080B24;
    display: flex;
    align-items: center;
    gap: 10px;

}
.total-subtotal-container {
    margin-top: 30px;
}
.total-subtotal-container .total, .add-sale-modal .total-subtotal-container .subtotal {
    display: flex;
    justify-content: space-between;
}
.total-subtotal-container section {
    margin-top: 10px;
} 
.add-sale-modal .total-subtotal-container .total .text, .add-sale-modal .total-subtotal-container .subtotal .text {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #616161;
}
.add-sale-modal .total-subtotal-container .total .amount, .add-sale-modal .total-subtotal-container .subtotal .amount {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
}
.add-sale-modal .total-subtotal-container .total {
    margin-top: 10px;
}
.add-sale-modal .btn-hold {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.pt {
    margin-top: 1.25rem;
}
.save-to-drafts {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    border: 1px solid #080B24;
    border-radius: 8px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #080B24;
}
.add {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    width: 112px;
    gap: 12px;
    background: #080B24;
    border-radius: 8px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.info {
    cursor: pointer;
}

.discount-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discount-selector {
    display: flex;
    align-items: center;
    gap: 20px;
}

.discount-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.discount-options {
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 24px;
}

.discount-active {
    background: #F08227 !important;
    color: #FFFFFF !important;
}

.discount-options .option {
    cursor: pointer;
    margin: auto 0;
    padding: 8px;
    border-radius: 8px;
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    background: #F4F4F4;
    color: #616161
}

.auto-suggest-dropdown {
    z-index: 20;
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    top: 110%;
    left: 0;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.auto-suggest-dropdown p {
    padding: 12px 20px;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #111111;
}
.auto-suggest-dropdown p:hover {
    cursor: pointer;
    background: #F08227;
    color: #FFFFFF;
}

.item-container {
    padding: 10px 24px;
    gap: 8px;
    width: 100%;
    background: #FDFDFD;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    margin-top: 20px;
}

.item-container h6 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
}

.item-container span {
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}

.item-container .quantity-edit, .item-container .total-delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.quantity-edit .pl, .total-delete .pl {
    padding-left: 5px;
}


@media ((max-width: 900px)) {
    .line-bd{
        border: none !important;
    }
    .qty{
        border-bottom: 2px solid #D1D1D1;
        padding-bottom: 10px;
        width: 100%;
    }
    .sales-flex{
        flex-wrap: wrap;
    }
    .add-sale-modal{
        width: 100%;
        height: 80%;
    }
    .debt, .returned-recovery{
        flex-direction: column;
    }
}
