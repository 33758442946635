.profile-container {
    margin-top: 30px;
}

.personal-information-container, .company-information-container {
    border: 1px solid #B2B3BB;
    border-radius: 8px;
    padding: 20px;
}

.company-information-container {
    margin-top: 20px;
    margin-bottom: 10px;
}

.input-form{
    width: 100%;
}
.profile-container .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.personal-information-container .btn-hold {
    margin-top: 25px;
    display: flex;
    gap: 10px;
}


@media (max-width: 900px) {
    .prof-mob{
        width: 100%;
    }
}