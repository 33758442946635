.pricing-plan {
  padding: 80px 0;
  width: 95%;
  margin: 0 auto;
}
.pricing-plan h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.0025em;
  color: #111111;
  text-align: center;
}
.pricing-plan h2 span {
  color: #f08227;
}
.pricing-plan p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 30px 0;
  text-align: center;
  color: #555555;
  width: 50%;
  margin: 0 auto;
}
.pricing-plan_toggle {
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 60px 0;
}
.pricing-plan_toggle h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.toggle-bg {
  position: relative;
  width: 78.17px;
  height: 39.09px;
  background: #e6e7e9;
  border-radius: 39.0854px;
  cursor: pointer;
}
.toggle-click {
  position: absolute;
  width: 31.27px;
  height: 31.27px;
  top: 4px;
  background: #f08227;
  border-radius: 50%;
}
.inactive-toggle {
  color: #616161;
}
.active-toggle {
  color: #f08227;
}
.month-toggle {
  right: 2px;
}
.year-toggle {
  left: 2px;
}
.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.advanced-pricing {
  position: relative;
  background: #080b24;
  box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 50px 0;
}
.advanced-pricing h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}
.advanced-pricing h5 {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 20px 0;
}
.advanced-pricing_img {
  position: absolute;
  right: 20px;
  top: 20px;
}
.pricing-line {
  border: 1px solid #f4f4f4;
  margin: 30px 0;
}
.pricing-button {
  height: 100px;
  background: #ffffff;
  border-radius: 16px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricing-button h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #080b24;
}
.pricing-button h4 span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.pricing-features {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}
.priority-logo {
  color: #f08227;
  position: absolute;
  bottom: 25px;
  right: 10px;
}
.pricing-features h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 0;
}
.features-list h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.pricing-getstarted {
  background: white !important;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 20px;
}
.basic-pricing {
  border: 1px solid #d1d1d1;
}
.basic_pricing-getstarted {
  color: white !important;
  background: #080b24 !important;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 20px;
}
.pricing-learnmore {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  color: white !important;
}
.basic_pricing-learnmore {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  color: #f08227 !important;
}
.basic-pricing {
  background: white;
}
.basic-pricing h3,
.basic-pricing h5 {
  color: #080b24;
}
.basic-pricing_button {
  background: #f7f7f7;
}
.basic-pricing_features h6 {
  color: #616161;
}
.basic-features_list h4 {
  color: #080b24;
}

/* mobile  */

@media (max-width: 900px) {
  .pricing-plan {
    width: 90%;
    margin: 0 auto;
  }
  .pricing-plan h2 {
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    width: 70%;
  }
  .pricing-plan p {
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    text-align: left;
    padding: 15px 0;
  }
  .pricing-plan_toggle h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .toggle-bg {
    width: 46.29px;
    height: 23.15px;
  }
  .toggle-click {
    width: 18.52px;
    height: 18.52px;
    top: 2px;
  }
  .pricing-cards {
    flex-direction: column;
  }
  .advanced-pricing {
    width: 100%;
  }
  .advanced-pricing h3 {
    font-size: 24px;
    line-height: 30px;
  }
  .advanced-pricing h5 {
    font-size: 14px;
    line-height: 22px;
  }
  .features-list h4 {
    font-size: 14px;
    line-height: 20px;
  }
  .advanced-pricing button {
    font-size: 16px;
    line-height: 24px;
  }
}
