
.items-page_header{
    /* border: 1px solid red; */
    position: relative;
}
.add-items_popup{
    position: absolute;
    top: 80px;
    right: 0;
    width: 480px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    z-index: 4;
    padding: 30px 30px 10px;
}
.add-items_popup-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.add-items_popup-head h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.add-item_form{
    padding: 25px 0;
}
.item-variant_cont{
    /* border: 1px solid red; */
    padding: 20px 0;
}
.item-variant_option{
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
}
.item-variant_option h4{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616161;
}
.item-variant_option img{
    width: 12px;
    height: 12px;
    cursor: pointer;
}
.item-variant_show{
    padding: 20px 0;
}
.item-show_allfields{
    padding: 40px 0;
}
.item-show_allfields p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: center;
    color: #111111;
}
.item-add_business{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.grouped-popup{
    width: 480px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    padding: 30px;
    transition: 0.7s;
}
.grouped-popup_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.grouped-popup_header h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
}
.grouped-items_btn{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 50px;
}