.success-msg-container, .failed-msg-container {
    position: fixed;
    padding: 20px;
    left: 50%;
    transform: translate(-50%, -50%);    
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    transition: 0.3s ease-out;
    z-index: 50;
}

.success-msg-container {
    background: #EDF7EE;
}

.failed-msg-container {
    background: #FFE6E6;
}

.success-msg-container.slide-out, .failed-msg-container.slide-out {
    top: -1000px;

}

.success-msg-container.slide-in, .failed-msg-container.slide-in {
    top: 10%;
}

.success-msg-container span, .failed-msg-container span {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
}

@media (max-width: 900px) {
    .success-msg-container{
        width: 100%;
    }
    .failed-msg-container{
        width: 100%;
    }
}