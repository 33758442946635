
.textarea-input{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.textarea-input textarea{
    font-family: 'Raleway';
    padding: 16px 2px 2px 16px;
    width: 100%;
    height: 180px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}

