

.new-composite_item{
    padding: 40px 0;
}
.new-composite_item input{
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.0025em;
    color: #A3A3A3;
    font-family: 'Nunito';
}
.new-composite_item h3{
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.0025em;
    color: #111111;
    text-transform: capitalize;
}
.composite-name::placeholder{
    color: #111111 !important;
}
.new-composite_item input::placeholder{
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.0025em;
    color: #A3A3A3;
    font-family: 'Nunito';

}
.new-composite_item-body{
    /* border: 1px solid red; */
    display: flex;
    gap: 50px;
    padding: 50px 0;
}
.new-composite_item-body-left{
    /* border: 1px solid green; */
    width: 65%;
}
.new-composite_item-body-left h2{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #616161;
}
.new-composite_item-body-right{
    /* border: 1px solid purple; */
    width: 35%;
}
.new-composite_item-body-right h3{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #616161;
}

.new-composite_item-body_table{
    border: 1px solid #EBEBEB;
    width: 100%;
    margin: 20px 0;
}
.new-item-body-right_img{
    width: 100%;
    border: none;
    background: transparent;
}
.new-item-body-right_img img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.grid-table{
    /* border: 2px solid red; */
    min-width: 100% ;
}
.grid-table input{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}
.grid-table input::placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}
/* .column1{
    border: 1px solid red;
    width: 40px;
}
.column2, .column3{
    border: 1px solid red;
    width: 10px;
} */
.grid-table p{
    padding: 0 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
}
.grid-table th{
    background: #E9F0F7;
}
/* .grid-table tbody tr{
    border: 1px solid red;
} */
.total-value{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    gap: 20px;
    width: 40%;
    margin-right: 20px;
}
.total-value input {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}
.total-value input::placeholder {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
}
.total-value p{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3A3A3;
}
.select-comp_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
}
/* .select-comp_items-row{
    position: relative;
} */
.select-comp_items-row input::placeholder{
    color: black;
}
.select-comp_items p{
    display: flex;
    gap: 20px;
    align-items: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: black;
}
.select-comp_items p img{
    width: 56px;
    height: 56px;
    border-radius: 16px;
}
.item-more{
    /* border: 1px solid red; */
    position: relative;
}
.composite-more_list{
    position: absolute;
    width: 136px;
    /* height: 129px; */
    filter: drop-shadow(0px 18px 28px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
    background-color: white;
    padding: 10px;
    right: 25px;
    top: 70px;
    z-index: 2;
}
.composite-more_list p{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    gap: 10px;
    display: flex;
    align-items: center;
    color: #616161;
    padding: 10px 0;
    cursor: pointer;
}