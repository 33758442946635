/* PRIVACY POLICY  */
.privacy-policy{
    background: var(--stockkeepercolours-greys-greys-100, #F7F7F7);
}
.privacy-policy_cont{
    width: 70%;
    margin: 0 auto;
    padding: 50px 0;
}
.privacy-policy_head h2{
    color: var(--stockkeepercolours-black, #111);
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 56px; /* 116.667% */
    letter-spacing: -0.12px;
    text-align: center;
}
.privacy-policy_head h2 span{
    color: var(--stockkeepercolours-brand-colours-2, #F08227);
}
.privacy-policy_head p{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
    padding: 30px 0;
}
.info-collect{
    padding: 20px 0;
}
.info-collect h3{
    color: var(--stockkeepercolours-black, #111);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    text-align: center;
    padding: 10px 0;
}
.info-collect p{
    color: var(--stockkeepercolours-black, #111);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    padding: 5px 0;
}
.info-collect p span{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
}
.when-collect{
    padding: 20px 0;
}
.when-collect h3{
    color: var(--stockkeepercolours-black, #111);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}
.when-collect p{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    padding: 20px 0 5px;
}
.data-security{
    padding: 10px 0;
}
.data-security h3{
    color: var(--stockkeepercolours-black, #111);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; 
    text-align: center;
}
.data-security p{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    padding: 10px 0;
}
.data-security ul{
    padding-bottom: 10px;
}
.data-security ul h4{
    color: var(--stockkeepercolours-black, #111);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    padding: 5px 0;
}
.data-security ul li{
    color: var(--stockkeepercolours-greys-greys-900, #616161);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    width: 95%;
    margin: 0 auto;
}