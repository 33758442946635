.empty-container {
    margin-top: 100px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-container {
    display: flex;
    justify-content: center;
}

.empty-container p {
    text-align: center;
    margin-top: 20px;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #616161;
}

.empty-container .btn-hold {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    height: fit-content;
}

.empty-container .btn-hold button {
    color: #080B24;
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 20px;
    border: 1px solid #080B24;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}