.faq{
    width: 85%;
    margin: 0 auto;
    padding: 70px 0;
}
.faq h2{
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.0025em;
    color: #111111;
    text-align: center;
}
.faq h2 span{
    color: #F08227;
}
.faq p{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;  
    color: #555555;  
    width: 50%;
    margin: 0 auto;  
    padding: 30px 0;
}
.faq-questions{
    width: 65%;
    margin: 0 auto;
    padding: 50px 0;
}
.each-question h5{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    padding: 20px 10px;
    border-bottom: 1px solid #D1D1D1;
    cursor: pointer;
}
.each-question h6{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
    padding: 5px 10px;
}


@media (max-width: 900px) {
    .faq{
        width: 90%;
    }
    .faq h2{
        font-size: 24px;
        line-height: 30px;
        text-align: left;
    }
    .faq p{
        font-size: 14px;
        line-height: 22px;
        width: 100%;
    }
    .faq-questions{
        width: 100%;
        padding: 30px 0;
    }
    .each-question h5{
        font-size: 14px;
        line-height: 20px;
    }
    .each-question h6{
        font-size: 14px;
        line-height: 20px;
    }
}