
.filter-pop_up{
    overflow: auto;
    width: 560px;
    min-height: auto;
    max-height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    /* border-radius: 20px; */
    padding: 30px 30px 10px;
}
.filter-pop_up-header h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #111111;
}
.filter-pop_up-header h2 span{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F08227; 
    cursor: pointer;   
}
.filter-body{
    padding: 40px 0;
}
.stock-level{
    padding: 20px 0;
}
.stock-level h3{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 10px;
    border-bottom: 1px solid #616161;
}
.filter-para{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #111111;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
}
.item-price{
    display: flex;
    justify-content: center;
    gap: 15px;
}
.filter-buttons{
    padding: 30px 0 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}
.stock-level_options p{
    color: #111;
    font-size: 1rem;
    font-family: Raleway;
    line-height: 1.5rem;
    padding: 10px 10px;
    font-weight: 400;
    cursor: pointer;
}
.selected{
    color: #EDF7EE;
    background-color: #F08227;
}


/* delete-popup */
.delete-popup{
    width: 560px;
    height: 254px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    padding: 30px;
}

.delete-popup h3{
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 30px;
    color: #111111;
    text-transform: capitalize;
}
.middle-popup_text{
    padding: 30px 0;
}
.middle-popup_text h4{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
    padding-bottom: 5px;
}
.middle-popup_text p{
    color: #616161;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
}
.delete-popup_btn{
    display: flex;
    justify-content: space-between;
}


.success-message{
    height: 58px;
    background: #EDF7EE;
    border-radius: 8px;
    top: 0%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    position: fixed;
    z-index: 10;
    transition: 0.7s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
    z-index: 12;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-50%)
    /* transform: translateY(-20px); */
}
.success-message h3{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 28px;
    color: #111111;
}
.delete-bt{
    width: 164px;
}

@media (max-width: 900px) {
    .filter-pop_up{
        width: 90%;
        height: 85%;
    }
    .success-message{
        width: 80%;
        height: auto;
        top: 20px;
    }
    .delete-popup{
        width: 97%;
        height: auto;
    }
    .delete-popup_btn{
        gap: 10px;
        width: 100%;
        flex-direction: column;
    }
    .delete-bt{
        width: 100%;
    }
}