.change-password-container {
    border: 1px solid #B2B3BB;
    border-radius: 8px;
    padding: 20px;
    margin-top: 30px
}

.change-password-container .flex {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.change-password-container .btn-hold {
    margin-top: 25px;
    display: flex;
    gap: 10px;
}

