
.notification-select{
    position: relative;
}

.notification-select_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    gap: 10px;
    height: 40px;
    border: 1px solid #A3A3A3;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A3A3A3;
    cursor: pointer;
}
.notification-select_block p{
    text-transform: capitalize;
}
.notification-select_block p span{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #EBEBEB;    
}
.notification-select_menu{
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    top: 45px;
    width: 262px;
    height: 569px;
    background: #FFFFFF;
    box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
    z-index: 4;
}
.notsItem{
    width: 100%;
}
.notification-select_menu p{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #111111;
    text-transform: capitalize;
    padding: 12px 16px;
}
.notsActive{
    background: #F7F7F7;
}

.date-picker_component p{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3A3A3;
}
.date-picker{
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    width: 90%;
    margin: 0 auto;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    overflow: hidden !important;
    cursor: pointer;
}
.nots-btn{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 16px;
    padding-top: 30px ;
    width: 100%;
}


/* export component  */
.export-cont{
    width: 35.5rem;
    height: 27.25rem;
    padding: 30px;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 100;
    transition: 0.7s;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 30px 30px 10px;
    /* border: 1px solid red; */
}
.export-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.export-header p{
    color: #252C32;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; 
    text-transform: capitalize;
}
.export-body{
    display: flex;
    justify-content: space-between;
    padding: 60px 40px;
}
.export-pdf-select{
    border: 1px solid #F08227;
    background: #FEF3E9;
}
/* .export-pdf:hover{
    border: 1px solid #F08227;
    background: #FEF3E9;
} */
.export-pdf{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.export-pdf p{
    color: #000;
    font-family: Nunito;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 125% */
}

@media (max-width: 900px) {
    .export-cont{
        width: 90%;
    }
    .export-body{
        flex-direction: column;
    }
    .new-width{
        width: 100% !important;
    }
    .export-button{
        justify-content: center;
        display: flex;
    }
}